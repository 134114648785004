#cc-home {
    width: 100%;
    margin: 0 auto;
    position: relative;
    height: 100vh;
    overflow-y: auto;

    .home-left {
        position: fixed;
        min-height: 100vh;
        top: 0;
        left: 0;

    }

    .home-right {
        position: absolute;
        min-height: 100vh;
        top: 0;
        right: 0;
        overflow-y: auto;
    }
}