@import "../sass/variables.scss";

#cc-review-table {
    background: $fifth-color;
    border-radius: 8px;
    padding: 1rem;
    max-height: 85vh;
    overflow-y: auto;



    thead {

        th {
            font-style: normal;
            font-weight: 500;
            font-size: 14px;
            color: $primary-color;

            box-shadow: inset 0px -1px 0px #d9dde285;

            // &:nth-child(1) {
            //     // border-radius: 10px 0px 0px 10px;
            // }

            // &:nth-child(5) {
            //     // border-radius: 0px 10px 10px 0px;
            // }
        }


    }

    tbody {


        td,
        th {
            font-style: normal;
            font-weight: 500;
            font-size: 14px;
            color: $secondary-color;

        }
    }
}