@import "./Dashboard/sass/variables.scss";

/* Box sizing rules */
*,
*::before,
*::after {
  box-sizing: border-box;
}


/* Set core root defaults */
html:focus-within {
  scroll-behavior: smooth;
}

/* Set core body defaults */
body {

  min-height: 100vh;
  text-rendering: optimizeSpeed;
  line-height: 1.5;
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  overflow-x: hidden;


}

/* Inherit fonts for inputs and buttons */
input,
button,
textarea,
select {
  font: inherit;
}

/* width */
::-webkit-scrollbar {
  width: .4rem;
}

/* Track */
::-webkit-scrollbar-track {
  background: $secondary-color;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: $primary-color;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: $tertiary-color;
}

.button-style {
  background: #913311;
  border-radius: 10px;
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  text-align: center;
  color: $secondary-color;
  border: 0;
  padding: 11px 29px;
  transition: all .3s ease;

  &:hover {
    background: #913311d3;
  }
}

@media (prefers-reduced-motion: reduce) {
  html:focus-within {
    scroll-behavior: auto;
  }

  *,
  *::before,
  *::after {
    animation-duration: 0.01ms !important;
    animation-iteration-count: 1 !important;
    transition-duration: 0.01ms !important;
    scroll-behavior: auto !important;
  }
}