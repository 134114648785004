@import "../sass/variables.scss";

#cc-stuff-title-card {
    border-radius: 8px;


    h5 {

        font-style: normal;
        font-weight: 700;
        font-size: 35px;
        text-align: center;
        letter-spacing: 1px;
        color: $secondary-color;
    }


}