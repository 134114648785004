@import "../sass/variables.scss";

#cc-header-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: sticky;
    top: 0%;

    .cc-header-icon-container {
        border-right: 2px solid $primary-color;
        margin-right: 1rem;

        span {
            left: 70%;
            top: 40%;
        }
    }

    .cc-header-icon {
        color: $tertiary-color;
        font-size: 1.3rem;
        // margin-right: 1rem;
        cursor: pointer;

        :hover {
            color: $primary-color;
        }
    }

    .header-notify-btn {
        background-color: transparent;
        margin-right: 1rem;
    }

    h1 {

        font-style: normal;
        font-weight: 700;
        font-size: 2rem;
        letter-spacing: 0.3px;
        color: $primary-color;
        overflow-y: hidden;
        text-transform: uppercase;
        margin: 0;

        span {
            color: $secondary-color;

        }
    }

    .header-right-info {
        p {
            font-style: normal;
            font-weight: 600;
            font-size: 14px;
            line-height: 20px;
            letter-spacing: 0.2px;
            color: $secondary-color;
            margin-right: .5rem;
            margin-bottom: 0;
        }

        img {
            border-radius: 50%;
            border: 2px solid $secondary-color;
            padding: 2px;
        }
    }
}