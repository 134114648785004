@import "../sass/variables.scss";

#cc-sales-summary-card {
    background: $primary-color;
    border-radius: 8px;
    // padding: 1.5rem;

    h5 {

        font-style: normal;
        font-weight: 700;
        font-size: 20px;
        line-height: 25px;
        text-align: center;
        letter-spacing: 0.4px;
        color: $secondary-color;
    }

    p {

        font-style: normal;
        font-weight: 700;
        font-size: 2rem;
        line-height: 44px;
        text-align: center;
        letter-spacing: 1px;
        color: $secondary-color;
        margin: 0;
    }
}