@import "../sass/variables.scss";

#cc-create-notification {
    background-color: $fifth-color;
    border-radius: 8px;
    padding: 2rem;

    h1 {
        font-style: normal;
        font-weight: 700;
        font-size: 1.5rem;
        line-height: 30px;
        letter-spacing: 0.3px;
        color: $secondary-color;

        span {
            color: $primary-color;
        }
    }

    .notify-img-container {
        background-color: $fifth-color;
        border-radius: 8px;
        display: flex;
        justify-content: center;
        align-items: center;
        color: $primary-color;
        font-size: 8rem;

    }

    .notify-img-file-container {
        position: relative;
        height: 100%;

        .delete-img-btn {
            position: absolute;
            top: 0;
            right: 0;
            background-color: $primary-color;
            color: $secondary-color;
            border: 0;
            padding: .5rem;
            font-size: 1.2rem;

        }

        img {
            background-color: white;
        }
    }

    .notify-add-img-txt {
        font-style: normal;
        font-weight: 700;
        font-size: 20px;
        color: $secondary-color;
        text-align: center;
        margin-top: .8rem;
        margin-bottom: 0;
    }

    .label-title-txt {

        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 20px;
        color: $secondary-color;
    }

    .notify-input-field {
        background-color: transparent;
        color: $secondary-color;
    }
}