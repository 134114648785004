@import "../sass/variables.scss";

#cc-sidebar-container {
    background-color: $fifth-color;
    width: 100%;
    min-height: 100vh;
    position: relative;

    .all-side-menu {
        height: 85vh;
        overflow-y: auto;
    }


    .cc-side-menu {
        cursor: pointer;

        .cc-side-menu-items {
            display: flex;
            align-items: center;
            transition: all .3s ease;

            .cc-menu-items-icon {
                color: $primary-color;
                margin-right: 1.5rem;
            }

            h5 {
                font-style: normal;
                font-weight: 400;
                font-size: 16px;
                line-height: 20px;
                letter-spacing: 0.2px;
                color: $secondary-color;
                margin: 0;

            }


        }


    }

    .cc-side-menu:hover {
        .cc-menu-items-icon {
            color: $secondary-color;

        }

        background: $primary-color;
        border-left: 2px solid $secondary-color;
    }

    .selected-style {

        .cc-menu-items-icon {
            color: $secondary-color  !important;

        }

        background: $primary-color;
        border-left: 2px solid $secondary-color;
    }
}