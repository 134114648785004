@import "../sass/variables.scss";

#cc-staff-details-info {
    background-color: $fifth-color;
    border-radius: 8px;


    h1 {
        font-style: normal;
        font-weight: 700;
        font-size: 1.5rem;
        line-height: 30px;
        letter-spacing: 0.3px;
        color: $secondary-color;

        span {
            color: $primary-color;
        }
    }

    table {

        font-style: normal;
        font-weight: 400;
        font-size: 20px;
        line-height: 25px;
        color: $secondary-color;

        .form-check-input:checked {
            background-color: #14CB5A;
            border-color: $primary-color;
        }
    }
}