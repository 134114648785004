@import "../sass/variables.scss";

#cc-sub-header-container {
    background-color: $fifth-color;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1.5rem;
    border-radius: 8px;

    .cc-sub-header-title-1 {
        font-style: normal;
        font-weight: 300;
        font-size: 20px;
        line-height: 25px;
        color: rgba(255, 255, 255, 0.87);

        span {
            font-weight: 600;
        }
    }

    .cc-sub-header-title-2 {
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 20px;
        color: rgba(255, 255, 255, 0.87);

        span {
            font-weight: 600;
        }
    }

    // button {
    //     background: #913311;
    //     border-radius: 10px;
    //     font-style: normal;
    //     font-weight: 400;
    //     font-size: 18px;
    //     text-align: center;
    //     color: $secondary-color;
    //     border: 0;
    //     padding: 11px 29px;
    //     transition: all .3s ease;

    //     &:hover {
    //         background: #913311d3;
    //     }
    // }
    @media only screen and (max-width: 600px) {
        flex-wrap: wrap;
        flex-direction: column-reverse;
    }

    @media only screen and (min-width: 768px) and (max-width:820px) {
        flex-wrap: wrap;
        flex-direction: column-reverse;
    }
}